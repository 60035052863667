import React from 'react';
import Myself from './myself.png'

const Me = () => {
  return (
    <section className="me">
      <img src={Myself} alt="Me"/>
    </section>
  )
}

export default Me;
